<template>
    <v-row>
        <v-col>
            <v-row v-if="!isLoggedIn" class="mt-10">
                <v-col>
                    <v-card
                        class="mx-auto"
                        max-width="480"
                        min-height="300"
                        color="appBackground"
                        flat
                    >
                        <v-card-title>
                            <h3 class="word__wrap text-center">
                                {{ $t(`favourites.notLogged`) }}
                            </h3>
                        </v-card-title>
                        <v-card-text
                            class="d-flex justify-center flex-column align-center"
                        >
                            <hero animation-is="" :size="200"></hero>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn
                                depressed
                                x-large
                                color="primary"
                                @click="register()"
                            >
                                {{ $t(`project.newProject.registerBtn`) }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else-if="getFavouritesStatus_Success" class="mt-2">
                <v-col cols="12">
                    <h2>{{ $t(`favourites.title`) }}</h2>
                </v-col>
                <v-col
                    v-for="(favourite, index) in favourites"
                    :key="index"
                    cols="12"
                    md="4"
                    xl="3"
                >
                    <project-info-card
                        :project="favourite"
                        :type="favourite.type"
                        card-width="372"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="372"
                        :thumbnail="favourite.youtube_url ? true : false"
                        @card-click="viewProject(favourite)"
                    >
                        <template #viewAction>
                            <view-project-btn
                                @view-project="viewProject(favourite)"
                            ></view-project-btn>
                        </template>
                    </project-info-card>
                </v-col>
                <v-col v-if="favourites.length <= 0" cols="12">
                    {{ $t("favourites.noItems") }}
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col v-if="getFavouritesStatus_Error" cols="12">
                    {{ $t("messages.error.sthWrong") }}
                </v-col>
                <loading-spinner
                    :show="getFavouritesStatus_Pending"
                ></loading-spinner>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { getFavourites } from "@/api/favouritesApi.js";

import { CONFIG } from "@/common/config.js";
import { getRole } from "@/helpers/generalHelpers.js";
import { mapGetters, mapState } from "vuex";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import Hero from "@/components/Heroes/Hero.vue";
export default {
    name: "Favourites",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
        LoadingSpinner,
        Hero,
    },

    data() {
        return {
            favourites: [],
            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            getFavouritesStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getFavouritesStatus"]),
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        async handleFavourites() {
            this.getFavouritesStatus = apiStatus.Pending;
            let url = `${getRole(this.user_role)}/favorites`;

            const { response, error } = await withAsync(getFavourites, url);

            if (error) {
                this.getFavouritesStatus = apiStatus.Error;
                return;
            }

            this.favourites = [
                ...response.data.data.projects_bookmarked,
                ...response.data.data.recipes_bookmarked,
                ...response.data.data.advices_bookmarked,
                ...response.data.data.libraries_bookmarked,
            ];

            this.getFavouritesStatus = apiStatus.Success;
        },
        viewProject(project) {
            let name;
            switch (project.type) {
                case "project":
                    name =
                        project.category == "garden"
                            ? "GardenInfo"
                            : "constructionInfo";
                    break;
                case "recipe":
                    name = "RecipeInfo";
                    break;
                case "advice":
                    name = "AdviceInfo";
                    break;
                case "library":
                    name = "LibraryInfo";
                    break;
            }
            this.$router.push({
                name: name,
                params: { id: project.id },
            });
        },
        register() {
            this.$router.push({ name: "Register" });
        },
    },

    created() {
        if (!this.isLoggedIn) return;
        this.handleFavourites();
    },
};
</script>
